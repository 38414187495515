export const styles = {
  global: {
    body: {
      bg: 'white',
      color: 'black',
      fontSize: '14px',
      fontFamily: `
        -apple-system, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
      `,
    },
  },
}
