import {
  Box,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Slide,
} from '@chakra-ui/react'
import React from 'react'

type Props = {
  error: { message: string }
  resetErrorBoundary: () => void
}

const Error: React.FC<Props> = ({ error, resetErrorBoundary }: Props) => (
  <Slide
    direction="top"
    in
    style={{ zIndex: 10, position: 'absolute' }}
  >
    <Alert status="error">
      <AlertIcon />
      <AlertTitle mr={2}>error</AlertTitle>
      <AlertDescription
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
      >
        {error.message}
        <Box display="flex" gap={4} pr={8}>
          <Button onClick={resetErrorBoundary}>reload</Button>
        </Box>
      </AlertDescription>
      <CloseButton position="absolute" right="8px" top="8px" />
    </Alert>
  </Slide>
)

export default Error
