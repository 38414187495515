import React from 'react'
import { atom, useRecoilValue, useSetRecoilState } from 'recoil'

import { initialApiClient, ApiClient } from '~/libs/ApiClient'

import { RecoilAtomKeys } from './StateKeys'

type ApiClientState = {
  apiClient: ApiClient
}

type SetApiClientState = ({ apiClient }: ApiClientState) => void

const apiClientRecoilState = atom<ApiClientState>({
  key: RecoilAtomKeys.API_CLIENT_STATE,
  default: { apiClient: initialApiClient },
})

export const useApiClientQueries = (): { apiClient: ApiClientState } => {
  const apiClient = useRecoilValue(apiClientRecoilState)

  return { apiClient }
}

export const useApiClientMutations = (): {
  setApiClient: SetApiClientState
} => {
  const setState = useSetRecoilState(apiClientRecoilState)

  const setApiClient = React.useCallback(
    ({ apiClient }: ApiClientState) =>
      setState({ apiClient }),
    [setState],
  )

  return { setApiClient }
}
