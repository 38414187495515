import {
  Box,
  useColorModeValue,
  useBreakpointValue,
} from '@chakra-ui/react'
import React, { FC, ReactNode, Suspense, useEffect, useState } from 'react'

import Loading from '~/components/base-parts/loading'
import NavigationBar from '~/components/common/navigation-bar'

export type AutoCloseValue = {
  sm: boolean
  md: boolean
  lg: boolean
  xl: boolean
  '2xl': boolean
}

type Props = {
  logo: ReactNode
  supportPageUrl?: string
  small?: boolean
  useAutoClose?: boolean | AutoCloseValue
  children: ReactNode
}

const getAutoCloseValue = (
  value?: boolean | AutoCloseValue,
): AutoCloseValue => {
  if (!value) {
    return {
      sm: false,
      md: false,
      lg: false,
      xl: false,
      '2xl': false,
    }
  }
  if (typeof value === 'boolean' && value) {
    return {
      sm: true,
      md: true,
      lg: false,
      xl: false,
      '2xl': false,
    }
  }
  return value
}

const DrawerLayout: FC<Props> = ({
  logo,
  small,
  useAutoClose,
  supportPageUrl,
  children,
}: Props) => {
  const autoClose = useBreakpointValue(getAutoCloseValue(useAutoClose))
  const [isSmall, onSmall] = useState(small)

  useEffect(() => {
    if (!useAutoClose || !autoClose) {
      return
    }
    onSmall(autoClose)
  }, [autoClose])

  return (
    <Box
      position="fixed"
      top="0"
      bottom="0"
      left="0"
      right="0"
      display="flex"
      w="full"
      h="full"
      bg={useColorModeValue('white', 'black')}
    >
      <NavigationBar logo={logo} supportPageUrl={supportPageUrl} />
      <Box
        zIndex="-1"
        position="absolute"
        pt="12"
        top="0"
        left="0"
        bottom="0"
        right="0"
        h="100%"
        w="100%"
        paddingX={{ base: 4, md: isSmall ? 8 : 14 }}
        className="bg"
        bgImage="/background.png"
        bgRepeat="repeat"
        // bgSize={{ base: 'auto', md: 'cover' }}
        // bgAttachment="local"
      >
        <Box position="relative" w="full" h="full" overflowY="auto">
          <Suspense fallback={<Loading />}>{children}</Suspense>
        </Box>
      </Box>
      <style>{`
        .bg {
          animation: scroll-anim 30s linear infinite;
        }

        @keyframes scroll-anim {
          100% {
            background-position: 0 100%;
          }
        }
      `}</style>
    </Box>
  )
}

export default React.memo(DrawerLayout)
