import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { QueryClientProvider, useQueryErrorResetBoundary } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import Error from '~/components/common/error'

import { PROCESS_ENV } from '~/config'
import { theme } from '~/constants/themes'
import { queryClient } from '~/libs/ReactQuery'
import ErrorModel from '~/models/error'
import { ApiClientProvider } from '~/providers/ApiClientProvider'

type AppProviderProps = {
  children: React.ReactNode
}

export const AppProvider = ({ children }: AppProviderProps): JSX.Element => {
  const { reset } = useQueryErrorResetBoundary()
  return (
    <ChakraProvider theme={theme}>
      <ErrorBoundary
        FallbackComponent={({
          error,
          resetErrorBoundary,
        }): React.ReactElement => {
          return (
            <Error
              error={new ErrorModel(error)}
              resetErrorBoundary={resetErrorBoundary}
            />
          )
        }}
        onReset={reset}
      >
        <RecoilRoot>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              {PROCESS_ENV.VITE_ENVIRONMENT === 'development' && (
                <>
                  <Helmet>
                    <link
                      rel="icon"
                      type="image/svg+xml"
                      href="/favicon.svg"
                    />
                  </Helmet>
                </>
              )}
              <BrowserRouter>
                <ApiClientProvider>{children}</ApiClientProvider>
              </BrowserRouter>
            </QueryClientProvider>
          </HelmetProvider>
        </RecoilRoot>
      </ErrorBoundary>
    </ChakraProvider>
  )
}
