/* eslint-disable */
import tokens from './tokens.json'

export type Typography = {
  [name: string]: {
    fontFamily: string
    fontSize: string
    fontWeight: string
    letterSpacing: string
    lineHeight: string
    paragraphSpacing: string
    _description: string
  }
}

export const getTypography = (): Typography => {
  const typography = {} as any
  Object.entries(tokens.global)
    .filter(([_, token]: any) => token.type === 'typography')
    .forEach((t: any) => {
      const [name, setting] = t
      const { value } = setting
      typography[name] = {
        ...value,
        fontWeight: value.fontWeight.replace('W', '') + '00',
        _description: setting.description,
      }
    })
  return typography as Typography
}
