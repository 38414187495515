import axios, { AxiosRequestConfig } from 'axios'

import { PROCESS_ENV } from '~/config'

const axiosConfig: AxiosRequestConfig = {
  timeout: Number(PROCESS_ENV.VITE_API_NETWORK_TIMEOUT_MS),
  baseURL: PROCESS_ENV.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'json',
}

export const initialApiClient = axios.create(axiosConfig)
export type ApiClient = typeof initialApiClient
