import { Image, Box, Text } from '@chakra-ui/react'
import React, { Suspense } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useWindowSize } from 'react-use'

import DrawerLayout from '~/components/common/drawer-layout'
import FullLoadingView from '~/components/common/full-loading-view'

import { navigate } from '~/utils/UseCustomNavigate'

const SUPPORT_PAGE_URL =
  'https://www.notion.so/c1208cbcdbbc40b1adb20865d3039544?v=6def1d4dc4794f57a9a92ff8dfd318bf'

export const MainLayout: React.FC = () => {
  const navFunction = useNavigate()
  const { width } = useWindowSize()
  return (
    <DrawerLayout
      logo={
        <Box
          display="flex"
          gap={2}
          alignItems="center"
          onClick={(event): void => navigate({ event, to: '/', navFunction })}
          cursor="pointer"
        >
          <Image borderRadius="full" boxSize="40px" src="/icon-192.png" />
          {width > 600 &&
            <Text fontSize={18} fontWeight={700}>
              Nagoya Mentor Portal
            </Text>
          }
        </Box>
      }
      supportPageUrl={SUPPORT_PAGE_URL}
    >
      <Suspense fallback={<FullLoadingView />}>
        <Outlet />
      </Suspense>
    </DrawerLayout>
  )
}

export default MainLayout
