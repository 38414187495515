export const PROCESS_ENV = {
  // environment
  VITE_ENVIRONMENT: import.meta.env.MODE,

  // Axios
  VITE_API_URL: String(import.meta.env.VITE_API_URL),
  VITE_API_NETWORK_TIMEOUT_MS: Number(
    import.meta.env.VITE_API_NETWORK_TIMEOUT_MS,
  ),
}
