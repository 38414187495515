import { NavigateFunction } from 'react-router-dom'

type Props = {
  event: React.MouseEvent
  to: string
  navFunction: NavigateFunction
}

// https://github.com/remix-run/react-router/blob/ea8eba843bf899daf8a51c2617d05c179b38369d/packages/react-router-dom/modules/Link.js#L7-L9
const isModifiedEvent = (event: React.MouseEvent): boolean => {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

export const navigate = ({ event, to, navFunction }: Props): void => {
  if (isModifiedEvent(event)) {
    window.open(to, '_blank')
    return
  }

  if (!event.defaultPrevented && event.button === 0) {
    navFunction(to)
  }
}
