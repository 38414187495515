import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

import MainLayout from '~/components/common/main-layout'

const Home = lazy(() => import('~/pages'))
const Mentor = lazy(() => import('~/pages/mentor'))
const ErrorNotFound = lazy(() => import('~/pages/error-not-found'))

export const AppRoutes = (): JSX.Element => (
  <Routes>
    <Route path="/" element={<MainLayout />}>
      <Route index element={<Home />} />
      <Route path="/mentor">
        <Route path=":id" element={<Mentor />} />
      </Route>
      <Route path="*" element={<ErrorNotFound />} />
    </Route>
  </Routes>
)
