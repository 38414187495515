import { AxiosError } from 'axios'

import { codeList } from '~/constants/error'
import { Maybe } from '~/types/AdvancedTypes'

export const statusCode2Message = (statusCode: number): Maybe<string> => {
  return codeList[statusCode] ?? null
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAxiosError = (error: any): error is AxiosError => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return !!error.isAxiosError
}
