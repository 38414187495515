import React from 'react'
import { createRoot } from 'react-dom/client'

import { AppProvider } from '~/providers/AppProvider'
import { AppRoutes } from '~/routes'

// React 18でReactDOM.renderはサポート外。createRootを使用する。
// https://ja.reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html
const container = document.getElementById('root')

if (container) {
  createRoot(container).render(
    <React.StrictMode>
      <AppProvider>
        <AppRoutes />
      </AppProvider>
    </React.StrictMode>,
  )
}
