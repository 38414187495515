import { extendTheme } from '@chakra-ui/react'

import { getPrimitiveColors } from './colors'
import { fonts } from './fonts'
import { fontSizes } from './fontSizes'
import { styles } from './styles'
import { getTypography } from './typography'

export const themes = {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  colors: { ...getPrimitiveColors() },
  fonts,
  fontSizes,
  styles,
  textStyles: getTypography(),
}

export const theme = extendTheme(themes)
