/* eslint-disable */
import tokens from './tokens.json'

export const getPrimitiveColors = () => {
  return Object.entries(tokens.global['PrimitiveColors']).reduce(
    (acc: any, [key, value]) => {
      acc[key.toLowerCase()] = Object.entries(value as any).reduce(
        (variationAcc: any, [variationKey, variationValue]: any) => {
          variationAcc[variationKey.toLowerCase()] = variationValue.value
          return variationAcc
        },
        {},
      )
      return acc
    },
    {},
  )
}
