import { Flex, HStack, Stack, IconButton, FlexProps } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { FiHelpCircle } from 'react-icons/fi'

interface Props extends FlexProps {
  logo: ReactNode
  supportPageUrl?: string
}

const NavigationBar = ({
  logo,
  supportPageUrl,
  ...rest
}: Props): React.ReactElement => {
  const onOpenSupportPage = (): void => {
    window.open(supportPageUrl)
  }
  return (
    <Flex
      pl={{ sm: 4, md: 8 }}
      pr={{ base: 4, md: 4 }}
      h="12"
      w="full"
      borderBottom="1px solid"
      borderColor="#D0D0D0"
      alignItems="center"
      justifyContent={{ base: 'space-between' }}
      {...rest}
    >
      <Stack spacing={{ base: 0, md: 6 }} flex={{ base: 'none', md: 1 }}>
        {logo}
      </Stack>
      <HStack spacing={{ base: 4, md: 6 }}>
        <Flex alignItems="center">
          {/* サポートページ */}
          {/* {supportPageUrl && (
            <IconButton
              onClick={onOpenSupportPage}
              variant="outline"
              aria-label="open support page"
              border="none"
              color="black"
              _hover={{
                background: '#E9E9E9',
              }}
              _focusVisible={{
                boxShadow: '0 0 0 2px white',
              }}
              icon={<FiHelpCircle size="24" />}
            />
          )} */}

          {/* menu 置きたければ */}
        </Flex>
      </HStack>
    </Flex>
  )
}

export default React.memo(NavigationBar)
