import { Flex } from '@chakra-ui/react'
import React from 'react'

import Loading from '~/components/base-parts/loading'

const FullLoadingView: React.FC = () => (
  <Flex
    flexDirection="column"
    width="100wh"
    height="100vh"
    justifyContent="center"
    alignItems="center"
  >
    <Loading />
  </Flex>
)

export default FullLoadingView
