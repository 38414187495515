import axios, { AxiosRequestConfig } from 'axios'
import { ReactNode, useEffect, useState } from 'react'

import FullLoadingView from '~/components/common/full-loading-view'

import { PROCESS_ENV } from '~/config'
import { useApiClientMutations } from '~/hooks/global/ApiClientState'
import useMounted from '~/utils/UseMounted'

export const ApiClientProvider = ({
  children,
}: {
  children: ReactNode
}): React.ReactElement => {
  const mountedRef = useMounted()
  const [loading, setLoading] = useState<boolean>(true)
  const { setApiClient } = useApiClientMutations()

  const setClient = (): void => {
    const axiosConfig: AxiosRequestConfig = {
      timeout: 100000,
      baseURL: PROCESS_ENV.VITE_API_URL,
    }
    setApiClient({
      apiClient: axios.create(axiosConfig),
    })
  }

  useEffect(() => {
    if (loading && mountedRef.current) {
      setClient()
      setLoading(false)
    }
  }, [mountedRef.current])

  return <>{loading ? <FullLoadingView /> : children}</>
}
