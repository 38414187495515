import { AxiosError } from 'axios'

import { UNKNOWN_ERROR_TEXT } from '~/constants/error'
import { Maybe } from '~/types/AdvancedTypes'

import { statusCode2Message, isAxiosError } from './helper'

export default class ErrorModel {
  private axiosError?: AxiosError
  private error?: Error
  private _message?: string
  private _statusCode?: number

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(error: any) {
    if (!error) {
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (error?.response?.data?.message) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      this._message = error.response.data.message
      return
    }
    if (typeof error === 'string') {
      this.error = new Error(error)
    } else if (typeof error === 'number') {
      this._statusCode = error
    } else if (isAxiosError(error)) {
      this.axiosError = error
    } else if (error instanceof Error) {
      this.error = error
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      this._statusCode = error.statusCode ?? null
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      this._message = error.message ?? null
    }
  }

  get message(): string {
    const messageFromStatusCode = this._statusCode
      ? statusCode2Message(this._statusCode)
      : null

    return (
      this.axiosError?.message ??
      this.error?.message ??
      this._message ??
      messageFromStatusCode ??
      UNKNOWN_ERROR_TEXT
    )
  }

  get statusCode(): Maybe<number> {
    return this._statusCode ?? null
  }
}
