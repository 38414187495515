import { Box } from '@chakra-ui/react'

import Spinner from '~/components/base-parts/spinner'

const Loading = (): React.ReactElement => (
  <Box display="flex" position="absolute" top="0" bottom="0" left="0" right="0" alignItems="center" justifyContent="center">
    <Spinner />
  </Box>
)

export default Loading
